import React from "react"
import { Typography } from "antd"

import Layout from "../components/layout"
import Head from "../components/head"

const { Title, Paragraph } = Typography

export default function Cgv() {
  return (
    <Layout>
      <Head
        title="CGV"
      />

      <div className="content-container space-around-row">
        <Title level='3'>Conditions Générales de Vente</Title>

        <Paragraph>
          Afin de faciliter leur mise à jour et impression, les Conditions Générales de Vente de la plateforme "RocketChart" sont accessibles via ce lien sécurisé :
        </Paragraph>

        <Paragraph>
          <a href="https://docs.google.com/document/d/1sYBthWn0wzBTwQ25VFQZVgEsM6DO0tpNjRuv3ps-mUs/edit?usp=sharing" target="_blank" rel="noopener noreferrer">Lire les Conditions Générales de Vente</a>
        </Paragraph>
      </div>
    </Layout>
  )
}
